<template>
  <comercial-tpl>
    <b-card
      class=" mx-auto"
      text-variant="left"
      align-v="center">
    <b-row>
      <b-col>ID:</b-col>
      <b-col>{{sql.id}}</b-col>
      <div class="w-100"></div>

      <b-col>Nome:</b-col>
      <b-col>{{sql.name}}</b-col>
      <div class="w-100"></div>

      <b-col>Responsável:</b-col>
      <b-col>{{sql.respname}}</b-col>
      <div class="w-100"></div>
      
      <b-col>E-mail:</b-col>
      <b-col>{{sql.email}}</b-col>
      <div class="w-100"></div>

      <b-col>Telefone:</b-col>
      <b-col>{{sql.tel}}</b-col>
      <div class="w-100"></div>
      
      <b-col>Ensino:</b-col>
      <b-col>{{sql.ensino}}</b-col>
      <div class="w-100"></div>

      <b-col>Bairro:</b-col>
      <b-col>{{sql.bairro}}</b-col>
      <div class="w-100"></div>
      
      <b-col>RA - Quem Indicou:</b-col>
      <b-col>{{sql.codigo+' - '+sql.indicador}}</b-col>
      <div class="w-100"></div>

      <b-col>Data do cadastro:</b-col>
      <b-col>{{sql.created_at}}</b-col>
      <div class="w-100"></div>

    </b-row>
    </b-card>


  </comercial-tpl>
</template>

<script>
// @ is an alias to /src
import ComercialTpl from '@/tpls/ComercialTpl.vue'

export default {
  name: 'VerIndicados',
  data() {
    return {
      sql: false,
      usuario: false,
    }
  },

  components: {
    ComercialTpl,
  },

  created(){
  
    let usuarioAux = this.$store.getters.getUsuario;
    if(usuarioAux){
      this.usuario = this.$store.getters.getUsuario;
      this.$http.get(this.$urlApi+'indique/ver/'+this.$route.params.id)
      
        .then(response => {
          //
          if(response.data.status){
            
            console.log(this.$route.params.id);
            this.sql = response.data.sql;
          }
        })
          
          .catch(e => {
            console.log(e)
            alert("Erro! Tente novamente mais tarde!");
          })  

      }
    },  
  
  methods:{

  }

}
</script>

